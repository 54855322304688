var zenDeskKey = '3ee88ff1-67b0-4826-b041-2c42edf607eb';
var initializationDelay = 5000;
export function init() {
    injectScript(function () {
        setCurrentLocale();
    }, initializationDelay);
}
function injectScript(onloadCallback, delay) {
    if (delay === void 0) { delay = 0; }
    var scriptElement = document.createElement('script');
    scriptElement.id = 'ze-snippet';
    scriptElement.src = "https://static.zdassets.com/ekr/snippet.js?key=".concat(zenDeskKey);
    scriptElement.onload = function () { return onloadCallback(); };
    setTimeout(function () {
        document.body.append(scriptElement);
    }, delay);
}
function setCurrentLocale() {
    var currentLocale = document.querySelector('html').lang || 'en-US';
    zE('messenger:set', 'locale', currentLocale);
}
