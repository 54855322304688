import lottie from 'lottie-web';
import { throttle } from 'lodash-es';
import { isElementInViewport } from './helpers';
export function init() {
    var elements = document.querySelectorAll('.lottie-animations-container');
    elements.forEach(function (element) {
        var _a = element.dataset, autoplay = _a.autoplay, loop = _a.loop, path = _a.path, playWhenElementInViewportSelector = _a.playWhenElementInViewportSelector;
        var animation = lottie.loadAnimation({
            container: element,
            renderer: 'svg',
            autoplay: autoplay === 'true',
            loop: loop === 'true',
            path: path,
        });
        // play animation once to show it statically
        animation.play();
        if (playWhenElementInViewportSelector) {
            setupAnimationPlayWhenElementInViewport(animation, playWhenElementInViewportSelector);
        }
    });
}
function setupAnimationPlayWhenElementInViewport(animation, elementSelector) {
    var playWhenElementInViewport = document.querySelector(elementSelector);
    if (!playWhenElementInViewport) {
        console.warn("Element not found: ".concat(elementSelector));
        return;
    }
    if (isElementInViewport(playWhenElementInViewport)) {
        return;
    }
    var scrollEventHandler = function () {
        if (isElementInViewport(playWhenElementInViewport)) {
            animation.goToAndPlay(0);
            removeEventListener('scroll', throttledScrollEventHandler);
        }
    };
    var throttledScrollEventHandler = throttle(scrollEventHandler, 100);
    addEventListener('scroll', throttledScrollEventHandler);
}
